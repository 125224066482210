import '@styles/globals.css';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import React, { ReactElement, ReactNode } from 'react';

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export const metadata = {
  title: 'Dreamy Whales',
  description: 'BOOP BOOP! Sound waves awaked the magnificent Dreamy Whales!',
  image:
    'https://dreamywhales.fra1.cdn.digitaloceanspaces.com/website/banner.jpg'
};

function App({ Component, pageProps: { ...pageProps } }: AppPropsWithLayout) {
  if (typeof window === 'undefined') React.useLayoutEffect = React.useEffect;

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <>
      <Head>
        <title>Dreamy Whales</title>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta property='og:title' content={metadata.title} />
        <meta property='og:description' content={metadata.description} />
        <meta property='og:image' content={metadata.image} />
      </Head>
      {getLayout(<Component {...pageProps} />)}
      <Script
        async
        src='https://www.googletagmanager.com/gtag/js?id=G-1M5E3TTVE0'
      ></Script>
    </>
  );
}

export default App;
